<template>
<v-container fluid class="px-lg-5">
  <div class="col-12">
    <v-card class="rounded-card" flat outlined>
      <assets-table
        @updateOptions="updateOptions"
        :assets="assets"
        :loading="loading"
        :showActions="false"
      >
      </assets-table>
    </v-card>
  </div>
</v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import assetsTable from 'components/assets/Table.vue';

export default {
  components: {assetsTable},
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      }
    };
  },
  computed: mapGetters(['assets']),
  methods: {
    ...mapActions(['getAssets']),
    updateOptions(options) {
      this.loading = true;
      this.getAssets({...options, uuid: this.$route.params.uuid}).then(() => (this.loading = false));
    }
  }
};
</script>
